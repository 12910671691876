module.exports = {
  siteTitle: '杨欢博士 —— 西北农林科技大学风景园林艺术学院', // <title>
  description: '杨欢博士任教于西北农林科技大学风景园林艺术学院，是风景学林专业硕士生导师，专注于传统村落文化遗产保护与人居空间更新和乡村建设规划、空间形态优化、社区韧性方向的研究工作，欢迎风景园林、城乡规划专业的学生报考',
  manifestName: 'yanghuan',
  manifestShortName: 'yanghuan', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#3389ae',
  manifestThemeColor: '#3389ae',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/avatar.jpg',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social

  authorName: '杨欢',
  authorHeading: ['西北农林科技大学风景园林艺术学院 副教授', '博士，硕士生导师'],
  authorBrief: [
    { title: '个人简介', content: ['西安建筑科技大学城乡规划学博士研究生', '2016-2017 年美国 UNC 访学交流'] },
    { title: '研究方向', content: ['传统村落文化遗产保护与人居空间更新', '乡村建设规划、空间形态优化、社区韧性'] },
    { title: '招生专业', content: ['风景园林、城乡规划专业'] },
    { title: '招生类型', content: ['风景学林专业硕士'] },
  ],
  authorSocialLinks: [
    { icon: 'fa-map-o', name: 'Location', url: 'https://surl.amap.com/c2teKutw4Fj' },
    {
      icon: 'fa-info',
      name: 'Campus',
      url: 'https://ylxy.nwafu.edu.cn/szdw/kjdw/2c06f39a987942b898cf5edbc9adf97e.htm',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:yanghuan@nwafu.edu.cn',
    },
  ],
};
