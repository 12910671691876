import React from 'react';
import config from '../../config';

export default function Content() {
  return (
    <article id="content">
      {config.authorBrief.map(brief => {
        const { title, content } = brief;
        return (
          <div key={title}>
            <p className="brief-title">{title}</p>
            {content.map(c => <p key={c}>{c}</p>)}
          </div>
        );
      })}
    </article>
  );
}
