import React from 'react';

export default function Footer() {
  return (
    <footer id="footer">
      <p className="copyright">
        西北农林科技大学风景园林艺术学院，712100
      </p>
      <p style={{ lineHeight: 1 }}>
        <a href='http://beian.miit.gov.cn/' target='_blank' rel='noreferrer' style={{ margin: '0 8px' }}>
          陕ICP备2022000347号-3
        </a>
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=61019002003134" rel="noreferrer" target="_blank" style={{ margin: '0 8px' }}>陕公网安备61019002003134</a>
      </p>
    </footer>
  );
}
